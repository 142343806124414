import React from "react"
import { llmParams } from "../constants/llmParams"
import { SupportInfo } from "../_utils/SupportInfo"


export const Params = ({ params, setParams }) => {

  return (
    <div style={{ width: "300px" }}>
      {llmParams.map((item, i) => {
        const { title, exp, max, min, defaultVal, dtype } = item

        return (
          <div key={i} style={{ width: "300px", padding: "5px" }}>
            {dtype === Boolean
              ?
              <label className="label" style={{ fontSize: "14px", fontWeight: 500 }}>
                <input type="checkbox" onClick={e => setParams({ ...params, [title]: e.target.checked })} />
                &nbsp;{title}
                <p style={{ fontSize: "10px", fontStyle: "italic" }}>{exp}</p>
              </label>
              :
              <label className="label" style={{ fontSize: "14px", fontWeight: 500 }}>
                {title}&nbsp;<SupportInfo i={i} exp={exp} styleProps={{ left: "120px" }} />
                <input
                  className="input is-small"
                  defaultValue={defaultVal}
                  onChange={e => {
                    params[title] = dtype === String
                      ? e.target.value
                      : dtype === Number
                        && (max !== undefined && Number(e.target.value) <= Number(max))
                        && (min !== undefined && Number(e.target.value) >= Number(min))
                        ? Number(e.target.value)
                        : null
                    setParams({ ...params })
                  }}
                />
              </label>
            }
          </div>
        )
      })}
    </div>
  )
}
