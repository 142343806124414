import { useState, Fragment } from "react"
import { Modal } from "./_utils/Elements"
import { handleGtag } from "./_utils/gtag"
var footerItems = [
  { title: "Github", link: "https://github.com/versionHQ/multi-agent-system" },
  { title: "Blog", link: "https://versionhq.substack.com/" },
  { title: "PyPI", link: "https://pypi.org/project/versionhq/" },
  { title: "Docs", link: "https://docs.versi0n.io" },
  { title: "Legal", link: "https://versi0n.io/terms.html" },
  { title: "Founder", link: "https://kuriko.vercel.app" },
  { title: "Contact", }
  // { title: "Privacy", link: "https://versi0n.io/privacy-policy.html" },
  // { title: "Contact", link: "https://versi0n.io/contact-us" },
]

export const Footer = ({ tabletMode, mobileMode }) => {
  const [displayModal, setDisplayModal] = useState(false)

  return (
    <Fragment>
      <footer style={{ minWidth: "100%" }}>
        <hr style={{ minWidth: "100%", margin: "0 0 5px 0" }} />

        <div itemScope itemType="https://schema.org/Organization" className="container" style={mobileMode ? { padding: "20px" } : { display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 10px 50px" }}>
          <a href="https://versi0n.io" onClick={e => handleGtag(e, "footer", "copyright")} aria-label="link">
            &copy; 2024-{new Date().getFullYear()} <span itemProp="legalName"> Version IO Sdn Bhd. </span>{(tabletMode || mobileMode) && <br />} All Rights Reserved
          </a>
          <span style={{ display: "none" }} itemProp="keywords">kuriko, kuriko iwai, kuriko i, version, versionhq, version io, autonomous agent networks, automomous agent, ai agent, llm, agentic, workflow</span>
          <span style={{ display: "none" }} itemProp="founder">Kuriko IWAI</span>
          <span style={{ display: "none" }} itemProp="founder">Kuriko I.</span>
          <span style={{ display: "none" }} itemProp="founder">Kuriko</span>
          <span style={{ display: "none" }} itemProp="name">Kuriko IWAI</span>
          <span style={{ display: "none" }} itemProp="name">Kuriko I.</span>
          <span style={{ display: "none" }} itemProp="name">Kuriko</span>
          <div style={mobileMode ? { display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px" } : { display: undefined }}>
            {footerItems.map((item, i) =>
              <a
                key={i}
                href={item.link} target="_blank" rel="noreferrer" style={{ marginRight: "20px" }} aria-label="footer item"
                onClick={e => { if (item.link === undefined) { setDisplayModal(!displayModal) }; handleGtag(e, "footer", item.title) }}
              >
                {item.title}
              </a>
            )}
          </div>
        </div>
      </footer >
      {displayModal && <Modal displayModal={displayModal} setDisplayModal={setDisplayModal} title={"Contact"} />}
    </Fragment>

  )
}
