import { MarkerType } from '@xyflow/react'


export const demoUrl = "https://business.linkedin.com/"

export const homeList = [
  {
    title: "Hello World",
    route: "hello-agent",
    filteredId: 0,
  },
  {
    title: "Hello Agent Network",
    route: "hello-agent-network",
    filteredId: 2,
  },
  // {
  //   title: "Pay Stripe",
  //   route: "hello-agent-network",
  //   filteredId: 2,
  // },
  {
    title: "Build your agent",
    route: "customizing-agent",
    filteredId: 4,
  },
  // {
  //   title: "Computer Use",
  //   route: "",
  //   filteredId: 0,
  // },
]

export const styledCriticalPath = {
  markerEnd: {
    type: MarkerType.Arrow,
    width: 10,
    height: 10,
    color: '#191b1b',
  },
  style: {
    strokeWidth: 1,
    stroke: '#191b1b',
  },
}

export const categories = [
  "hello series", "personal", "geek", "finance", "make your own",
  // "legal", "marketing",
]

export const demoCodeList = [
  {
    title: "Hello Agent",
    codeString: `import versionhq as vhq

agent = vhq.Agent(role='Demo Manager', goal='greet {name}')

res = agent.start()
`,
    category: categories[0],
    api: "hello-agent",
    vars: [
      { key: "name"}
    ],
    isActive: true,
    initNodes: [
      { id: '1', position: { x: 10, y: 10 }, data: { label: '1. Name' } },
      { id: '2', position: { x: 10, y: 100 }, data: { label: '2. Greet' } },
    ],
    initEdges: [
      { id: 'e1-2', source: '1', target: '2' },
    ],
    features: [
      "Configures an agent.",
      "Recognizes the given name.",
      "Generates a response.",
    ]
  },
  {
    title: "Hello Task",
    codeString: `from pydantic import BaseModel

import versionhq as vhq

class Demo(BaseModel):
  description: str
  goal: str

task = vhq.Task(
  description="generate a randome task with description and goal",
  response_schema=Demo,
)

res = task.execute()
`,
    category: categories[0],
    api: "hello-task",
    isActive: true,
    initNodes: [
      { id: '1', position: { x: 10, y: 10 }, data: { label: '1. Description' } },
      { id: '2', position: { x: 200, y: 10 }, data: { label: '2. Goal' } },
      { id: '3', position: { x: 80, y: 190 }, data: { label: '3. Response' } },
    ],
    initEdges: [
      { id: 'e1-3', source: '1', target: '3' },
      { id: 'e2-3', source: '2', target: '3' },
    ],
    features: [
      "Configures an agent.",
      "Configures a task with a random description.",
      "Executes the task and generates a response."
    ]
  },
  {
    title: "Hello Agent Network",
    codeString: `import versionhq as vhq

network = vhq.form_agent_network(
  task=f"Create a promo plan for the following client: {client_overview}",
  expected_outcome="media mix, key messages, and CTA targets.",
  context="{context}",
)

res, tg = network.launch()
`,
    category: categories[0],
    api: "hello-agent-network",
    vars: [
      {
        key: "client_overview",
      },
      {
        key: "context"
      }
  ],
    isActive: true,
    initNodes: [
      { id: '1', position: { x: 120, y: 10 }, data: { label: 'Process client, context' } },
      { id: '2', position: { x: 120, y: 100 }, data: { label: 'Define goals' } },
      { id: '3', position: { x: 10, y: 200 }, data: { label: 'Media' }, style: { width: 100 }},
      { id: '4', position: { x: 145, y: 200 }, data: { label: 'Messages' }, style: { width: 100 }},
      { id: '5', position: { x: 280, y: 200 }, data: { label: 'Estimate CTA'}, style: { width: 100 }},
      { id: '6', position: { x: 120, y: 300 }, data: { label: 'Overall plan' } },
      { id: '7', position: { x: 120, y: 400 }, data: { label: 'Response'} }
    ],
    initEdges: [
      { id: 'e1-2', source: '1', target: '2' },
      { id: 'e2-3', source: '2', target: '3' },
      { id: 'e2-4', source: '2', target: '4' },
      { id: 'e2-5', source: '2', target: '5' },
      { id: 'e3-6', source: '3', target: '6' },
      { id: 'e4-6', source: '4', target: '6' },
      { id: 'e5-6', source: '5', target: '6' },
      { id: 'e6-7', source: '6', target: '7' },
    ],
    features: [
      "Processes the given inputs.",
      "Generates agents and forms an agent network.",
      "Configures and feeds tasks.",
      "Activates the network and generates a final response.",
    ]
  },
  {
    title: "Planning Trip",
    codeString: `from pydantic import BaseModel

import versionhq as vhq

# defines expected outcome
class Trip(BaseModel):
  itinerary: str
  location: str
  cousine: str
  why_its_suitable: str


# generates a task graph
task_graph = vhq.workflow(
  Trip,
  context="Planning a suprise day trip for my friend to celebrate her birthday. She likes Korean foods.",
)


# executes
_, all_outputs = task_graph.activate()
`,
    category: categories[1],
    api: "hello-task-graph",
    isActive: true,
    initNodes: [
      { id: '1', position: { x: 120, y: 10 }, data: { label: 'Process context' } },
      { id: '2', position: { x: 120, y: 100 }, data: { label: 'Select location' } },
      { id: '3', position: { x: 10, y: 150 }, data: { label: 'Select cousine' } },
      { id: '4', position: { x: 120, y: 200 }, data: { label: 'Draft itinerary' } },
      { id: '5', position: { x: 120, y: 300 }, data: { label: 'Reasoning' } },
      { id: '6', position: { x: 120, y: 400 }, data: { label: 'Response'} }
    ],
    initEdges: [
      { id: 'e1-2', source: '1', target: '2' },
      { id: 'e2-3', source: '2', target: '3' },
      { id: 'e2-4', source: '2', target: '4' },
      { id: 'e2-5', source: '2', target: '5' },
      { id: 'e3-5', source: '3', target: '5' },
      { id: 'e4-6', source: '4', target: '6' },
      { id: 'e5-6', source: '5', target: '6' },
    ],
    features: [
      "Processes the structured output format.",
      "Generates a task graph with task nodes and edges.",
      "Configures agents.",
      "Activates the task graph.",
      "Generates a final response.",
    ],
  },
  {
    title: "Agent", // geek
    codeString: `import versionhq as vhq

agent = vhq.Agent(
  role='Demo Runner',
  goal='answer the following question using knowledge: {question}'
)

agent.update(
  llm='gemini-2.0',
  llm_config = dict(temperature=1, top_p=0.8, stop='Response'),
  knowledge_sources = [
    "https://pmc.ncbi.nlm.nih.gov/articles/PMC5790555/",
    "{knowledge_source_1}",
    "{knowledge_source_2}",
    "{knowledge_source_3}",
  ],
  with_memory=True
)

answer = agent.start()
`,
    category: categories[2],
    vars: [
      {
        key: "knowledge_source_1",
        title: "Add knowledge sources:",
        placeholder: "url or text",
      },
      {
        key: "knowledge_source_2",
        // placeholder: "url",
      },
      {
        key: "knowledge_source_3",
        // placeholder: "url",
      },
      {
        key: "question",
        title: "Ask a question: ",
        placeholder: "Did cats domesticate us by exploiting our weakness for cute?",
        type: "textarea",
      }, 
    ],
    api: "customizing-agent",
    isActive: true,
    initNodes: [
      { id: '1', position: { x: 120, y: 10 }, data: { label: 'Process context' } },
      { id: '2', position: { x: 120, y: 100 }, data: { label: 'Archive sources' } },
      { id: '3', position: { x: 120, y: 150 }, data: { label: 'Query knowledge' } },
      { id: '4', position: { x: 10, y: 200 }, data: { label: 'Query memories' } },
      { id: '5', position: { x: 120, y: 250 }, data: { label: 'Reasoning'} },
      { id: '6', position: { x: 120, y: 300 }, data: { label: 'Response' } },
    ],
    initEdges: [
      { id: 'e1-2', source: '1', target: '2' },
      { id: 'e2-3', source: '2', target: '3' },
      { id: 'e3-4', source: '3', target: '4' },
      { id: 'e2-5', source: '2', target: '5' },
      { id: 'e3-6', source: '3', target: '6' },
      { id: 'e4-6', source: '4', target: '6' },
      { id: 'e5-6', source: '5', target: '6' },
      { id: 'e6-7', source: '6', target: '7' },
    ],
    features: [
      "Configures the agent with given conditions.",
      "Archives and query knowledge sources.",
      "Query contextual memories.",
      "Finalizes a response.",
    ]
  },
  {
    title: "Eval",
    codeString: `import versionhq as vhq

network = vhq.form_agent_network(
  task="{task}",
  expected_outcome="{expected_outcome}"
)

res, tg = network.launch()

eval = tg.evaluate(
  eval_criteria=["{criteria_1}", "{criteria_2}", "{criteria_3}"]
)
`,
    category: categories[2],
    vars: [
      {
        key: "task",
        placeholder: ""
      },
      {
        key: "expected_outcome",
      },
      {
        key: "criteria_1",
        placeholder: "accuracy"
      },
      {
        key: "criteria_2",
        placeholder: "timeline",
      },
      {
        key: "criteria_3",
        placeholder: "expense",
      },
    ],
    api: "eval",
    isActive: true,
    initNodes: [
      { id: '1', position: { x: 120, y: 10 }, data: { label: 'Task handling' } },
      { id: '1-2', position: { x: 145, y: 80 }, data: { label: 'Response' }, style: { width: 100 }},
      { id: '2', position: { x: 120, y: 150 }, data: { label: 'Process criteria' } },
      { id: '3', position: { x: 0, y: 200 }, data: { label: 'Query memories' } },
      { id: '4-1', position: { x: 10, y: 250 }, data: { label: 'Score' },  style: { width: 100}, },
      { id: '5-1', position: { x: 10, y: 300 }, data: { label: 'Reasoning' }, style: { width: 100}, },
      { id: '6-1', position: { x: 10, y: 350 }, data: { label: 'Suggestion'}, style: { width: 100}, },
      { id: '4-2', position: { x: 160, y: 250 }, data: { label: 'Score' }, style: { width: 100}, },
      { id: '5-2', position: { x: 160, y: 300 }, data: { label: 'Reasoning' }, style: { width: 100}, },
      { id: '6-2', position: { x: 160, y: 350 }, data: { label: 'Suggestion'}, style: { width: 100}, },
      { id: '4-3', position: { x: 300, y: 250 }, data: { label: 'Score' }, style: { width: 100}, },
      { id: '5-3', position: { x: 300, y: 300 }, data: { label: 'Reasoning' }, style: { width: 100}, },
      { id: '6-3', position: { x: 300, y: 350 }, data: { label: 'Suggestion'}, style: { width: 100}, },
      { id: '7', position: { x: 120, y: 450 }, data: { label: 'Summary' } },
      { id: '7-2', position: { x: 300, y: 500 }, data: { label: 'Revaluate' }, style: { width: 100, backgroundColor: "#414141", color: "#fafafa"}},
      { id: '8', position: { x: 120, y: 550 }, data: { label: 'Response' } },
    ],
    initEdges: [
      { id: 'e1-2', source: '1', target: '2' },
      { id: 'e2-3', source: '2', target: '3' },
      { id: 'e2-4-1', source: '2', target: '4-1' },
      { id: 'e3-4-1', source: '3', target: '4-1' },
      { id: 'e2-4-2', source: '2', target: '4-2' },
      { id: 'e3-4-2', source: '3', target: '4-2' },
      { id: 'e2-4-3', source: '2', target: '4-3' },
      { id: 'e3-4-3', source: '3', target: '4-3' },
      { id: 'e4-1-5-1', source: '4-1', target: '5-1' },
      { id: 'e4-2-5-2', source: '4-2', target: '5-2' },
      { id: 'e4-3-5-3', source: '4-3', target: '5-3' },
      { id: 'e5-1-6-1', source: '5-1', target: '6-1' },
      { id: 'e5-2-6-2', source: '5-2', target: '6-2' },
      { id: 'e5-3-6-3', source: '5-3', target: '6-3' },
      { id: 'e6-1-7', source: '6-1', target: '7' },
      { id: 'e6-2-7', source: '6-2', target: '7' },
      { id: 'e6-3-7', source: '6-3', target: '7' },
      { id: 'e7-7-2', source: '7', target: '7-2' },
      { id: 'e7-8', source: '7', target: '8' },
    ],
    features: [
      "Processes evaluation criteria.",
      "Configures an evaluator agent.",
      "Performs few-shot learning, evaluates, and scores on a scale of 0 to 1.",
      "Generates a final response.",
    ]
  },
  {
    title: "Stock Analysis",
    codeString: `import versionhq as vhq
from pydantic import BaseModel
from typing import List

class TradingSignal(BaseModel):
    strong_buy: bool
    hold: bool
    sell: bool
    justification: str


rag_tool = vhq.RagTool(
  url="https://finance.yahoo.com/quote/{ticker}/analysis/",
  query="What are the latest ratings from the analyst?"
)

agent_a = vhq.Agent(
  role="Historical Price Analysis",
  knowledge_sources=['https://finance.yahoo.com/quote/{ticker}/history/', ]
)
agent_b = vhq.Agent(role="Sentiment Analysis", knowledge_sources=["https://finance.yahoo.com/quote/{ticker}/news/"])
agent_c = vhq.Agent(role="Fundamental Metrics", tools=[rag_tool,])
leader = vhq.Agent(role="Market Strategist", goal="analyze market context")

task_a = vhq.Task(description="collect insights from histrical price data of the given ticker.")
task_b = vhq.Task(description="run sentimental analysis of the given stock and list up insights")
task_c = vhq.Task(description="read the given finacial reports carefully, and list up fundamental metrics to consider whether to buy the stock")
task = vhq.Task(description="analyze the provided context and classify the given stock as either strong buy, hold, or sell. Provide a concise justification for your classification.", response_schema=TradingSignal)

node_a = vhq.Node(task=task_a, assigned_to=agent_a)
node_b = vhq.Node(task=task_b, assigned_to=agent_b)
node_c = vhq.Node(task=task_c, assigned_to=agent_c)
node = vhq.Node(task=task, assigned_to=leader)

tg = vhq.TaskGraph()
tg.add_node(node_a)
tg.add_node(node_b)
tg.add_node(node_c)
tg.add_node(node)

def completed(nodes: List[vhq.Node]):
    for item in nodes:
        if not item.task.output.raw:
            return False
    return True

condition = vhq.Condition(
    type=vhq.ConditionType.AND,
    methods={ "0": completed },
    args={ "0": dict(nodes=[node_a, node_b, node_c]), }
)

tg.add_dependency(node_a.identifier, node.identifier, dependency_type=vhq.DependencyType.FINISH_TO_START, condition=condition, required=True)
tg.add_dependency(node_b.identifier, node.identifier, dependency_type=vhq.DependencyType.FINISH_TO_START, condition=condition, required=True)
tg.add_dependency(node_c.identifier, node.identifier, dependency_type=vhq.DependencyType.FINISH_TO_START, condition=condition, required=True)

tg.concl_response_schema = TradingSignal
res, all_outputs = tg.activate()
`,
    category: categories[3],
    vars: [
      {
        key: "ticker",
        placeholder: "NVDA",
      },
    ],
    api: "stock-analysis",
    isActive: true,
    initNodes: [
      { id: '1-1', position: { x: 10, y: 10 }, data: { label: 'Price data' }, style: { width: 100}},
      { id: '2-1', position: { x: 145, y: 10 }, data: { label: 'News feed' }, style: { width: 100}},
      { id: '3-1', position: { x: 280, y: 10 }, data: { label: 'Fin reports' }, style: { width: 100}},
      { id: '1-2', position: { x: 10, y: 80 }, data: { label: 'Technical indicators' }, style: { width: 100, height: 50}},
      { id: '2-2', position: { x: 145, y: 80 }, data: { label: 'Sentiment' }, style: { width: 100, height: 50}},
      { id: '3-2', position: { x: 280, y: 80 }, data: { label: 'Fundamental metrics' }, style: { width: 100, height: 50}},
      { id: '4', position: { x: 145, y: 200 }, data: { label: 'Market context' } },
      { id: '5', position: { x: 145, y: 300 }, data: { label: 'Reasoning'} },
      { id: '6', position: { x: 145, y: 400 }, data: { label: 'Direction' } },
    ],
    initEdges: [
      { id: 'e1-2', source: '1-1', target: '1-2' },
      { id: 'e2-3', source: '2-1', target: '2-2' },
      { id: 'e3-4', source: '3-1', target: '3-2' },
      { id: 'e2-5', source: '1-2', target: '4', ...styledCriticalPath },
      { id: 'e3-6', source: '2-2', target: '4', ...styledCriticalPath },
      { id: 'e4-6', source: '3-2', target: '4', ...styledCriticalPath },
      { id: 'e5-6', source: '4', target: '5' },
      { id: 'e6-7', source: '5', target: '6' },
    ],
    features: [
      "Processes multiple data streams.",
      "Generates comprehensive stock analyses by evaluating technical indicators, assessing fundamental metrics, and identifying market trends across different timeframes.",
      "Generates a customized response.",
    ]
  },
  {
    title: "Make Your Own",
    codeString: `import versionhq as vhq`,
    category: categories[4],
    api: "make-your-own",
    isActive: true,
    initNodes: [],
    initEdges: [],
    features: [
      <a href="https://docs.versi0n.io" target='_blank' rel="noreferrer" style={{ textDecoration: "underline"}}>Documentation</a>
    ],
  },  
  //   {
  //     title: "Learning graph algo",
  //     codeString: `import versionhq as vhq

  // prof = vhq.Agent(
  //   role="Professor",
  //   goal="Teaching core concept using knowledge sources",
  //   knowledge_sources=[]
  // )
  // task = vhq.Task(description="Summarize the concepts using the given knowledge.")
  // task_2 = vhq.Task()

  //     `,
  //     category: categories[0]
  //   },
  //   {
  //     title: "Hello Agent",
  //     codeString: `import versionhq as vhq

  // agent = vhq.Agent(role="")
  //       `,
  //     detailUrl: "/playground",
  //     category: categories[0],
  //   },
  //   {
  //     title: "Learn Grapth Theory",
  //     codeString: `import versionhq as vhq

  // agent = vhq.Agent()
  //       `,
  //     detailUrl: "/playground",
  //     category: "featured",
  //   },
]


export const resultCodeList = [
  {
    title: "your agents",
    codeString: `import versionhq as vhq`,
    category: categories[5],
    api: "",
    isActive: true,
    initNodes: [],
    initEdges: [],
    features: [
      <a href="https://docs.versi0n.io" target='_blank' rel="noreferrer" style={{ textDecoration: "underline"}}>Documentation</a>
    ],
  },
]


// <--- LEGACY --->

// export const usecaseList = [
//   {
//     title: "Hello World",
//     codeString: `import versionhq as vhq

// task = vhq.Task(description='greet {name}')
// task.execute()
// `,
//     detailUrl: "/playground",
//     isHome: true
//   },
//   {
//     title: "Hello Agent Network",
//     codeString: `import versionhq as vhq
  
// network = vhq.form_agent_network(
//   task=f"create a promo plan to attract the client: {client_overview}",
//   expected_outcome='media mix, key messages, and CTA targets.'
// )
// network.launch()`,
//     detailUrl: "/playground",// connect with playground url later
//     isHome: true
//   },
//   {
//     title: "Customizing agents",
//     codeString: `import versionhq as vhq

// agent = vhq.Agent(
//   role='Demo Manager',
//   goal='run a demo successfully'
// )

// ## updating LLM config and adding knowledge, tools, and memories.
// agent.update(
//   llm='gemini-2.0',
//   llm_config = dict(temperature=1, top_p=0.1, stop='Response'),
//   knowledge_sources = [
//     <URL>,
//     <FILE_PATH_TO/demo.csv>,
//     <FILE_PATH_TO/demo.pdf>,
//   ],
//   tools=[vhq.Tool(func=lambda x: x * 5)],
//   use_memory=True
// )`,
//     detailUrl: "/playground",
//     isHome: false
//   },
  // {
  //   title: "Stripe Payment",

  // },
  // {
  //   title: "Hubspot",

  // },
// ]
