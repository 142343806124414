export const formationList = [
  {
    img: null,
    title: "Auto",
    usage: "Let the system decide the best formation.",
    id: null,
  },
  {
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/v1737893140/pj_m_agents/tglrxoiuv7kk7nzvpe1z.jpg",
    title: "Solo Agent",
    usage: "A single agent with tools, memories, and knowledge sources.",
    usecase: "An email agent draft promo messages for the given audience.",
    id: 1,
  },
  {
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/v1737893139/pj_m_agents/hzpchlcpnpwxwaapu1hr.jpg",
    title: "Random",
    usage: "A single agent handles tasks while asking help from other agents.",
    usecase: "An email agent drafts promo messages for the given audience, asking insights on tones from other email agents which oversee other audience clusters.",
    id: 4,
  },
  {
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/v1737893142/pj_m_agents/kyc6neg8m6keyizszcpi.jpg",
    title: "Squad",
    usage: "Multiple agents handle tasks synchronically while sharing tools, memories, and knowledge sources.",
    usecase: "An email agent and social media agent share the product knowledge and deploy multi-channel outbound campaign together.",
    id: 3,
  },
  {
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/v1737893141/pj_m_agents/hxngdvnn5b5qdxo0ayl5.jpg",
    title: "Supervising",
    usage: "A leader agent directs subordinates, while sharing its tools, memories, and knowledge sources with them.",
    usecase: "The leader agent strategize outbound campaign plans, and assign components to each subordinate agent or network.",
    id: 2,
  },
]
