import { useEffect, useState } from "react"
import { apiRouteUrl } from "../../lib/.api"


export const LogStream = ({ start, selectedId, outputList, processing }) => {
  const [logs, setLogs] = useState([])


  useEffect(() => {
    const handleStream = async () => {
      // setLogs([...logs,])

      await fetch(`${apiRouteUrl}/stream`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Origin": window.location.origin,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then(res => {
          if (res.status !== 200 || res.data === null) { setLogs([]) }
          else return Promise.resolve(res.clone().json())
        })
        .then(res => {
          const logsToAdd = res["logs"]
          if (logs.length === 0) setLogs(logsToAdd)
          else {
            const uniqueLogs = logs.filter(item => logsToAdd.indexOf(item) === -1)
            setLogs([...logsToAdd, ...uniqueLogs])
          }
        })
        .catch(err => { setLogs([]) })
    }

    if (start && selectedId !== null && outputList === null && processing === true) {
      const interval = setInterval(() => {
        handleStream()
      }, 1500)
      return () => clearInterval(interval)
    }
    else { setLogs([]) }
  }, [start, selectedId, outputList, processing])


  return (
    logs.length === 0
      ? <></>
      : logs.map((item, i) => <pre style={{ fontSize: "10px" }} key={i}>{item}</pre>)
  )
}
