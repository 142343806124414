import React from "react"
import { statusList } from "../constants/statusList"


export const OutputDetails = ({ statusId, tokens, latency }) => {
  const statusDescription = statusId !== null || statusId !== undefined ? statusList[statusId].description : ""

  return (
    <div >
      <p className="help" style={{ margin: 0 }}>
        Status:&nbsp;
        <span>
          {statusDescription}
        </span>
      </p>
      {tokens &&
        <p className="help" style={{ margin: 0 }}>
          Tokens:&nbsp;
          <span>
            {tokens}
          </span>
        </p>
      }
      {latency &&
        <p className="help" style={{ margin: 0 }}>
          Latency:&nbsp;
          <span>
            {Math.round(latency * 1000) / 1000} ms
          </span>
        </p>
      }
    </div>
  )
}


// export const OutputWindow = ({ output }) => {
//   // const getOutput = () => {
//   //   let statusId = outputDetails?.status?.id

//   //   if (statusId === 6) {
//   //     // compilation error
//   //     return (
//   //       <pre className="px-2 py-1 font-normal text-xs text-red-500">
//   //         {window.atob(outputDetails?.compile_output)}
//   //       </pre>
//   //     )
//   //   } else if (statusId === 3) {
//   //     return (
//   //       <pre className="px-2 py-1 font-normal text-xs text-green-500">
//   //         {window.atob(outputDetails.stdout) !== null
//   //           ? `${window.atob(outputDetails.stdout)}`
//   //           : null}
//   //       </pre>
//   //     )
//   //   } else if (statusId === 5) {
//   //     return (
//   //       <pre className="px-2 py-1 font-normal text-xs text-red-500">
//   //         {`Time Limit Exceeded`}
//   //       </pre>
//   //     )
//   //   } else {
//   //     return (
//   //       <pre className="px-2 py-1 font-normal text-xs text-red-500">
//   //         {outputDetails}
//   //       </pre>
//   //     )
//   //   }
//   // }

//   return (
//     <div className="w-full h-56 bg-[#1e293b] rounded-md text-white font-normal text-sm overflow-y-auto">
//       {/* {outputDetails ? <>{getOutput()}</> : null} */}
//       <pre className="px-2 py-1 font-normal text-xs text-red-500">
//         {output ? output : ""}
//       </pre>
//     </div>
//   )
// }


