import React from "react"
import { formationList } from "../constants/formationList"
import { SupportInfo } from "../_utils/SupportInfo"


export const Formation = ({ formationId, setFormationId }) => {

  return (
    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexWrap: "wrap" }}>
      {formationList.map((item, i) => {
        const { img, title, usage, usecase, id } = item

        return (
          <div key={i} >
            <div style={{ dispaly: "flex", flexDirection: "column", alignItems: "center", minWidth: "240px", maxWidth: "240px", padding: "10px 20px", border: formationId === id ? "1px solid black" : undefined, }}>
              <div style={{ marginBottom: "10px" }}>
                <label className="radio">
                  <input
                    type="radio"
                    checked={Boolean(formationId === id)}
                    onClick={() => { setFormationId(formationId !== id ? id : null) }}
                  />
                  &nbsp;
                  <span style={{ fontSize: "14px", fontWeight: 700 }}>{title}</span>
                  {usecase && <SupportInfo i={i} exp={usecase} styleProps={{ "left": "100px" }} />}
                  <p className="help">{usage}</p>

                </label>
              </div>
              {img && <img src={img} alt={title} width={120} style={{ maxHeight: "120px" }} />}
            </div>
          </div>
        )
      })}
    </div>
  )
}
