export const llmParams = [
  {
    title: "temperature", defaultVal: 1, min: 0, max: 2, dtype: Number,
    exp: "Randomness of text generated by LLM. Scaled from 0 to 2.0. Low: conservative, stick to the patterns learned, High: more explorative"
  },
  {
    title: "top_p", defaultVal: 0.8, min: 0, max: 1, dtype: Number,
    exp: "Randomness of the words based on the cumulative probability distribution until it reaches a threshold p ranged from 0 to 1.0. Low: less words considered, High more words considered"
  },
  {
    title: "top_k", defaultVal: 30, min: 10, max: 100, dtype: Number,
    exp: "Randomness of the worlds based on the selection of tokens until it reaches a threshold k. Low: deterministic and focused, High: diverse and creative."
  },
  {
    title: "frequency_penalty", defaultVal: 0, max: 2, min: -2, dtype: Number,
    exp: "Discourages the model from repeating words or phrases that have appeared frequently within the generated text. Scaled from -2.0 to 2.0. Helps to avoid excessive repetition of common words."
  },
  {
    title: "presence_penalty", defaultVal: 0, max: 2, min: -2, dtype: Number,
    exp: "Discourages the model from repeating words or phrases that have appeared at all within a certain context window. Scaled from -2.0 to 2.0. Encourages the model to introduce new words and concepts."
  },
  {
    title: "max_tokens", defaultVal: 8192, dtype: Number,
    exp: "The maximum number of tokens to generate."
  },
  {
    title: "stop", defaultVal: null, dtype: String,
    exp: "Stop tokens/sequenses where the API will stop generating tokens."
  },
  {
    title: "n", defaultVal: 1, max: 10, min: 1, dtype: Number,
    exp: "How many completions to generate for each prompt."
  },
  {
    title: "ignore_context_window", defaultVal: false, dtype: Boolean,
    exp: "Whether to follow the model's default context window. (True - you can add more context to the prompt, but cost you more)"
  }
]
