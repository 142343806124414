import { useState } from "react"
import { IoMdArrowRoundBack } from "react-icons/io"
import { handleGtag } from "../_utils/gtag"
import { demoCodeList, categories } from "../constants/usecaseList"


export const CodeSideMenu = ({ tabletMode, mobileMode, setCode, setSelectedId, selectedId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const smallScreen = (mobileMode || tabletMode)

  const MenuList = () => {
    return (
      <div style={{ width: "160px", fontSize: "12px", padding: "10px" }}>
        <h2 style={{ fontWeight: 600, fontSize: "16px" }}>Menu</h2>

        {categories.map((c, i) => {
          return (
            <div key={i}>
              <h3 style={{ fontSize: "12px" }}>{c}</h3>
              {demoCodeList
                .filter(item => item.isActive === true)
                .filter(item => item.category === c)
                .map((item, id) => {
                  return (
                    <div key={id}>
                      <button
                        style={{ textAlign: "left", fontWeight: selectedId === demoCodeList.indexOf(item) ? 700 : 200, color: selectedId === demoCodeList.indexOf(item) ? "#00d1b2" : undefined }}
                        onClick={e => {
                          setCode(item["codeString"])
                          setSelectedId(selectedId === demoCodeList.indexOf(item) ? 0 : demoCodeList.indexOf(item));
                          handleGtag(e, "demo")
                        }}>
                        {item.title}
                      </button>
                    </div>
                  )
                })
              }
              <hr style={{ margin: "10px 0" }} />
            </div>
          )
        })}
      </div >
    )
  }

  return (
    <div>
      {smallScreen && <div><IoMdArrowRoundBack onClick={() => setIsOpen(!isOpen)} /></div>}
      {isOpen && <MenuList />}

      {!smallScreen && <MenuList />}
    </div>
  )
}