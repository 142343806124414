import React from "react"
import { handleGtag } from "../_utils/gtag"


export const ModelButton = ({ id, model, setSelectedModelId, ...props }) => {
  const { name, logo, exp, isNew } = model

  return (
    <button
      aria-label="model"
      style={{ display: "flex", justifyContent: "center", margin: "5px", padding: "10px", minWidth: "240px", maxWidth: "240px", borderRadius: "10px" }}
      onClick={e => { handleGtag(e, "model button", name); setSelectedModelId(id) }}
      disabled={props.disabled !== undefined ? props.disabled : false}
    >
      <img src={logo} alt={name.slice(0, 2)} width={50} height={50} style={{ marginRight: "10px", height: "max-content" }} />
      <div style={{ textAlign: "left", minWidth: "160px", maxWidth: "160px" }}>
        <p style={{ fontSize: "14px", fontWeight: 700 }}>
          {name}&nbsp;
          {isNew && <span className="tag is-black" style={{ height: "14px", width: "32px" }}>New</span>}</p>
        <p style={{ fontSize: "12px", marginTop: "5px" }}>{exp}</p>
      </div>
    </button>
  )
}
