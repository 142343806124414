import { useState, Fragment } from "react"
import { FaGithub } from "react-icons/fa"
import { Modal } from "./_utils/Elements"
import { handleGtag } from "./_utils/gtag"
import { returnSelfUserId, logout } from "../lib/.auth"
var userId = returnSelfUserId()
const handleLogout = e => { logout(); window.scrollTo({ top: 0, behavior: 'smooth' }); handleGtag(e, "header", "logout") }

export const Header = ({ mobileMode, tabletMode, maintenanceMode, darkMode }) => {
  const [isOpenBurger, setIsOpenBurger] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)

  return (
    <Fragment>
      {mobileMode || tabletMode
        ?
        <nav className="navbar">
          <div className="navbar-brand">
            <div className="navbar-start" style={{ display: "flex", maxWidth: "100px" }}>
              <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center", maxWidth: "100px" }} href="/" onClick={e => handleGtag(e, "header", "icon")}>
                <img
                  src={darkMode
                    ? "https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1739113431/pj_m_home/pj_m_logo_z7hav2.png"
                    : "https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1728219760/pj_m_home/j80bjzkwuvtethz1dybf.png"
                  }
                  alt="Home" height={40} width={96} style={{ minHeight: "40px", maxHeight: "40px", height: "40px", minWidth: "96px", width: "96px", maxWidth: "96px" }}
                />
              </a>
            </div>

            <div className={isOpenBurger ? "Burger navbar-burger is-active" : "Burger navbar-burger"} onClick={() => setIsOpenBurger(!isOpenBurger)}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>

          {isOpenBurger &&
            <div className="navbar-dropdown" id="burger-menu">
              {/* {!maintenanceMode &&
                <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }}
                  href="/models" onClick={e => handleGtag(e, "header")}>
                  Models
                </a>
              } */}

              {!maintenanceMode &&
                <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/playground" onClick={e => handleGtag(e, "header")}>
                  Playground
                </a>
              }

              <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://pypi.org/project/versionhq" target="blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
                PyPI
              </a>
              <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://docs.versi0n.io" target="blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
                Docs
              </a>
              <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://github.com/versionHQ/multi-agent-system" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", "github")}>
                Github <FaGithub style={{ height: "20px" }} />
              </a>
              <a aria-label="navbar item" className="navbar-item" href="https://kuriko.vercel.app" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
                Founder  <span style={{ display: "none" }} itemProp="founder">Kuriko I.</span>
              </a>
              {/* <a aria-label="navbar item" className="navbar-item" href="/contact-us" onClick={e => handleGtag(e, "header")}>
                Contact Us
              </a> */}
              {/* {!maintenanceMode &&
                <button aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }}
                  onClick={e => { setDisplayModal(true); handleGtag(e, "header") }}>
                  Demo
                </button>
              } */}

              <hr style={{ margin: "10px 0" }} />

              {userId &&
                <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleLogout(e)}>
                  Logout
                </a>
              }
            </div>
          }
        </nav>
        :
        <nav className="navbar container" style={{ display: "flex", padding: "0 20px" }}>
          <div className="navbar-start" style={{ display: "flex" }}>
            <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleGtag(e, "header", "icon")}>
              <img
                src={darkMode
                  ? "https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1739113431/pj_m_home/pj_m_logo_z7hav2.png"
                  : "https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1728219760/pj_m_home/j80bjzkwuvtethz1dybf.png"
                }
                alt="Home" height={40} width={96} style={{ minHeight: "40px", maxHeight: "40px", height: "40px", minWidth: "96px", width: "96px", maxWidth: "96px" }}
              />
            </a>

            {!maintenanceMode &&
              <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }}
                href="/playground" onClick={e => handleGtag(e, "header")}>
                Playground
              </a>
            }

            {/* {!maintenanceMode &&
              <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }}
                href="/models" onClick={e => handleGtag(e, "header")}>
                Models
              </a>
            } */}
            {/* <div className="navbar-item has-dropdown is-hoverable">
              <p className="navbar-link">About</p>
              <div className="navbar-dropdown">
                <a aria-label="navbar item" className="navbar-item" href="https://versi0n.io" target="blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
                  Platform
                </a>
                <a aria-label="navbar item" className="navbar-item" href="https://kuriko.vercel.app" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
                  Founder <span style={{ display: "none" }} itemProp="founder">Kuriko Iwai</span>
                </a>
                <a aria-label="navbar item" className="navbar-item" href="/contact-us" onClick={e => handleGtag(e, "header")}>
                  Contact Us
                </a>
              </div>
            </div> */}
          </div>

          <div className="navbar-end" style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
            {/* {!maintenanceMode &&
              <button aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }}
                onClick={e => { setDisplayModal(true); handleGtag(e, "header") }}>
                Demo
              </button>
            } */}
            {/* <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://pypi.org/project/versionhq" target="blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
              PyPI
            </a> */}

            <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://docs.versi0n.io" target="blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
              Docs
            </a>

            {/* <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://www.linkedin.com/company/versionhq/?viewAsMember=true" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", "linkedin")}>
              <FaLinkedin style={{ height: "100px" }} />
            </a>
            <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://versionhq.substack.com/" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", "substack")}>
              <SiSubstack style={{ width: "20px" }} />
            </a> */}
            <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://github.com/versionHQ/multi-agent-system" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", "github")}>
              <FaGithub style={{ height: "100px" }} />
            </a>

            {userId &&
              <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleLogout(e)}>
                Logout
              </a>
            }
          </div>
        </nav>
      }

      <Modal displayModal={displayModal} setDisplayModal={setDisplayModal} />
    </Fragment>
  )
}
