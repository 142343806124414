import React from "react"

export const MaintenanceMode = ({ dt }) => {
  return (
    <div className="container content" style={{ minHeight: "700px", padding: "120px 80px", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <p>Under maintenance</p>
      {dt && <p>We'll be back at {dt}</p>}
      <img
        src="https://res.cloudinary.com/dfeirxlea/image/upload/v1740655717/pj_m_home/vhqndpatrbmu8zwudf1n.png"
        alt="versionhq"
        width={240}
        height={240}
      />
    </div>
  )
}
