import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ModelButton } from "./ModelButton"
import { Params } from "./Params"
import { modelList } from "../constants/modelList"
import { Formation } from "./Formation"
import { StickyLauncher, StickyMesssageBar } from "../_utils/Elements"
import { handleGtag } from "../_utils/gtag"
import { apiRouteUrl } from "../../lib/.api"
import { llmParams } from "../constants/llmParams"


const Breadcrumb = ({ id, setId, menus }) => {
  return (
    <nav className="breadcrumb is-centered" aria-label="breadcrumbs" style={{ margin: "50px 0 30px" }}>
      <ul>
        {menus.map((item, i) => {
          const { title } = item
          return (
            <>
              <li key={i} className={id === menus.indexOf(item) ? "is-active" : undefined}>
                <p onClick={() => setId(menus.indexOf(item))} >
                  {title}
                </p>
              </li>
              {i < menus.length - 1 && <p style={{ margin: "0 10px" }}>{"-"}</p>}
            </>
          )
        })}
      </ul>
    </nav >
  )
}


export const AgentSetupWindow = ({ selectedModelId, isSmallScreen, darkMode }) => {
  const navigate = useNavigate()
  const [id, setId] = useState(0)
  const menus = [
    { title: "Agent Config", },
    { title: "Task Config", },
    { title: "Network Formation", },
  ]

  const [params, setParams] = useState({})
  const [task, setTask] = useState({})
  const [formationId, setFormationId] = useState(null)

  const [start, setStart] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)

  const [apiKeyModal, setApiKeyModal] = useState(false)
  const [provider, setProvider] = useState(null)
  const [apiCreds, setApiCreds] = useState(null)
  const [apiError, setApiError] = useState(false)


  useEffect(() => {
    const ext = async () => {
      setProcessing(true)

      let finalParams = {}
      llmParams.forEach(item => finalParams[item["title"]] = params["title"] ? params["title"] : item["defaultVal"])

      let model_key = "key" in modelList[selectedModelId]
        ? modelList[selectedModelId]["key"]
        : modelList[selectedModelId]["credential"] === true && provider
          ? modelList[selectedModelId]["providers"][provider]["key"]
          : null

      const formData = apiCreds !== null
        ? { params: finalParams, task, formationId, model_key, api_creds: apiCreds }
        : { params: finalParams, task, formationId, model_key }

      await fetch(`${apiRouteUrl}/model`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Origin": window.location.origin,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...formData })
      })
        .then(res => {
          if (res.status !== 200 || res.data === null) { setProcessing(false); setStart(false); setError(true); }
          else return Promise.resolve(res.clone().json())
        })
        .then(res => {
          setProcessing(false); setStart(false);
          navigate("/result", { state: { res, model: modelList[selectedModelId] } })
        })
        .catch(err => { setError(true); setProcessing(false); setStart(false) })

      setProcessing(false)
    }

    if (start) ext()
  }, [start, formationId, selectedModelId, task, params, navigate, apiCreds, provider])


  if (selectedModelId === null) return <StickyMesssageBar color="is-danger is-light"><p>Model is not selected</p></StickyMesssageBar>
  return (
    <div>
      <Breadcrumb menus={menus} id={id} setId={setId} />

      <div className="content" style={{ display: "flex", justifyContent: "centerx" }}>
        {id === 0 &&
          <div className="content" style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row" }}>
            <ModelButton
              id={selectedModelId}
              model={modelList[selectedModelId]}
              selectedModelId={selectedModelId}
              disabled={true}
            />
            <Params params={params} setParams={setParams} />
          </div>
        }

        {id === 1 &&
          <div style={{ padding: "20px", width: "560px" }}>
            <label className="label" style={{ fontSize: "14px", fontWeight: 700 }}>
              Task overview
              <span className="help" style={{ fontWeight: 500 }}>Briefly describe the task you will assign to the agent.</span>
              <textarea className="textarea" onChange={e => { task["task"] = e.target.value; setTask({ ...task }) }} />
            </label>

            <label className="label" style={{ fontSize: "14px", fontWeight: 700 }}>
              Expected outcome <span className="help" style={{ fontWeight: 500 }}>Briefly describe the desired outcome.</span>
              <textarea className="textarea" onChange={e => { task["expected_outcome"] = e.target.value; setTask({ ...task }) }} />
            </label>

            <label className="label" style={{ fontSize: "14px", fontWeight: 700 }}>
              Context<span className="help" style={{ fontWeight: 500 }}>*Optional. Briefly describe context of the request.</span>
              <textarea className="textarea" onChange={e => { task["context"] = e.target.value; setTask({ ...task }) }} />
            </label>
          </div>
        }

        {id === 2 &&
          <Formation formationId={formationId} setFormationId={setFormationId} />
        }
      </div>

      <StickyLauncher>
        <div className="buttons">
          {id + 1 < 3 &&
            <button className="button" onClick={() => setId(id + 1)}>Next</button>
          }
          <button
            className={processing ? "button is-dark is-loading" : "button is-dark"}
            onClick={e => {
              handleGtag(e, "modal");
              if (modelList[selectedModelId]["credential"] === true) setApiKeyModal(true)
              else setStart(true)
            }}>
            Launch
          </button>
        </div>
      </StickyLauncher>

      {error &&
        <StickyMesssageBar color="is-danger is-light"><p className="help">Something went wrong.</p></StickyMesssageBar>
      }

      {apiKeyModal === true &&
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setApiKeyModal(false)}></div>
          <div className={darkMode ? "modal-content has-background-dark" : "modal-content has-background-white-ter"} style={{ padding: "20px", borderRadius: "20px" }}>

            {apiError && provider &&
              <StickyMesssageBar color="is-danger is-light">
                <p className="help">
                  To launch the agent, issue your API key on the <a href={modelList[selectedModelId]["providers"][provider]["providerUrl"]} style={{ textDecoration: "underline" }} target="_blank" rel="noreferrer">official website</a>.
                </p>
              </StickyMesssageBar>
            }

            <div style={{ marginBottom: "20px" }}>
              <p>Provider</p>
              {
                Object.keys(modelList[selectedModelId]["providers"]).map((item, i) =>
                  <label className="radio" key={i} style={{ marginLeft: "12px" }}>
                    <input
                      type="radio"
                      checked={Boolean(provider === item)}
                      onClick={e => setProvider(e.target.checked ? item : null)}
                      style={{ marginRight: "5px" }}
                    />
                    {item}
                  </label>
                )
              }
            </div>

            {provider !== null &&
              <div style={{ marginBottom: "20px" }}>
                <p>Credentials</p>
                <p className="help">
                  *Visit the <a href={modelList[selectedModelId]["providers"][provider]["providerUrl"]} style={{ textDecoration: "underline" }} target="_blank" rel="noreferrer">provider's website</a>.
                </p>

                {modelList[selectedModelId]["providers"][provider]["credentials"].map((item, i) => {
                  return (
                    <div key={i} style={{ margin: "12px" }}>
                      <label style={{ fontSize: "12px" }}>{item.replaceAll("_", " ")}
                        <input
                          className="input" type="password" style={{ width: "240px", margin: "0 10px" }}
                          onChange={e => { setApiCreds({ ...apiCreds, [item]: e.target.value }) }}
                        />
                      </label>
                    </div>
                  )
                })
                }
                <StickyLauncher>
                  <button className="button is-dark is-small"
                    onClick={e => { handleGtag(e, "demo api key"); if (!apiCreds) setApiError(true); else { setApiKeyModal(false); setStart(true) } }}>
                    Proceed
                  </button>
                </StickyLauncher>
              </div>
            }
          </div>
        </div>
      }

    </div >
  )
}


