import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { NewsBar } from "./_utils/Elements"
import { modelList } from "./constants/modelList"
import { ModelButton } from "./agent/ModelButton"
import { CodeDemoWindow } from "./code/CodeDemoWindow"
import { handleGtag } from "./_utils/gtag"
import { homeList } from "./constants/usecaseList"
import { AgentSetupWindow } from "./agent/AgentSetupWindow"


export const Home = ({ mobileMode, tabletMode, darkMode }) => {
  const { hash } = useLocation()
  const [demoId, setDemoId] = useState(null)
  const [modelStartId, setModelStartId] = useState(0)
  const [selectedModelId, setSelectedModelId] = useState(null)

  const defaultNum = mobileMode ? 1 : tabletMode ? 3 : 5
  const containerStyle = { maxWidth: mobileMode ? "100%" : tabletMode ? "700px" : "1200px", display: "flex", flexDirection: "column", alignItems: "center" }
  const isSmallScreen = mobileMode || tabletMode

  useEffect(() => {
    const el = hash !== "" ? Number(hash.replace("#", "")) : null
    if (el !== null && modelList.length > el) {
      setSelectedModelId(el)
    }
  }, [hash])

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <link rel="canonical" href="https://versi0n.io" />
        <title>version | Agentic orchestration framework for task automation</title>
        <meta name="description" content="Agentic orchestration frameworks for task automation" />
      </Helmet>

      <div className="container content" style={{ padding: isSmallScreen ? "50px 20px" : "100px 50px", ...containerStyle, minHeight: mobileMode ? undefined : tabletMode ? "2000px" : undefined }}>

        <NewsBar
          newsTitle="35K+ Downloads"
          link={"https://pypi.org/project/versionhq/"}
          fontSize={isSmallScreen ? "12px" : "16px"}
        />

        <h1 style={{ lineHeight: 1.5, fontWeight: 700, padding: "0 0 10px 0" }} itemProp="description">
          Build and share <span style={{ background: "-webkit-linear-gradient(left, #00d1b2, #169d87)", backgroundClip: "text", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>agentic workflows</span>
        </h1>

        <div style={{ maxWidth: "1000px", textAlign: "center" }}>
          <h2 style={{ fontSize: "16px", fontWeight: 500 }}>
            Autonomous agent networks to handle task automation with multiple reasoning steps
          </h2>
          <h3 style={{ fontSize: "14px", fontWeight: 400 }}>
            Autonomous agents will define tasks, generate tools, and self-improve task outputs while optimizing resources.
          </h3>
        </div>

        <div style={{ display: "flex", flexDirection: mobileMode ? "column" : undefined, alignItems: mobileMode ? "center" : undefined, height: mobileMode && demoId === null ? "300px" : mobileMode ? "1200px" : undefined }}>
          {homeList
            .filter(item => mobileMode && demoId !== null ? homeList.indexOf(item) === demoId : item)
            .map((item, i) => {
              const { title, filteredId } = item
              const index = homeList.indexOf(item)

              return (
                <div key={i} style={{ dispaly: "flex", flexDirection: "column", alignItems: "center", minWidth: "200px", maxWidth: "200px", marginBottom: "16px", minHeight: demoId !== null ? "2400px" : mobileMode ? undefined : "200px" }}>
                  <button
                    className={demoId === i ? "button is-dark has-text-primary" : "Hover button is-primary is-outlined"}
                    aria-label="button"
                    style={{ margin: "15px 15px 5px", display: "block", height: "42px", width: "180px", maxWidth: "180px", fontSize: "16px", fontWeight: 500 }}
                    onClick={e => { setDemoId(demoId === null ? index : demoId === index ? null : index); handleGtag(e, "home") }}
                  >
                    {title}
                  </button>

                  {demoId === index &&
                    <div style={{ marginTop: "30px", position: "absolute", left: isSmallScreen ? "5vw" : "-10vw", width: mobileMode ? "360px" : tabletMode ? "640px" : undefined }}>
                      <p className="help">
                        <a href={`/playground/#${filteredId}`} style={{ textDecoration: "underline" }}>{title.toLowerCase()}</a> built with versionhq
                      </p>
                      <CodeDemoWindow filteredId={filteredId} tabletMode={tabletMode} mobileMode={mobileMode} darkMode={darkMode} />
                    </div>
                  }
                </div>
              )
            })}
        </div>
      </div>

      <div className="container content" style={{ ...containerStyle }}>
        <p style={{ fontSize: "18px", fontWeight: 700 }}>Models:</p>
      </div>

      <hr style={{ margin: 0 }} />

      <div style={{ display: "flex", width: "100%", justifyContent: "center", maxHeight: mobileMode ? undefined : "100px", minHeight: "100px" }}>
        <button aria-label="previous" onClick={e => {
          setModelStartId(
            modelStartId - defaultNum < 0 && defaultNum === 1
              ? modelList.length - 1
              : modelStartId - defaultNum < 0
                ? 0
                : modelStartId - defaultNum
          )
          handleGtag(e, "home", "model back")
        }}>
          <IoIosArrowBack />
        </button>

        {modelList
          .sort((a, b) => Number(b.isNew) - Number(a.isNew))
          .sort((a, b) => Number(b.isHighlight) - Number(a.isHighlight))
          .filter(item => (modelList.indexOf(item) >= modelStartId && modelList.indexOf(item) < Number(modelStartId) + defaultNum))
          .map((item, i) => {
            return (
              <ModelButton
                key={i}
                id={modelList.indexOf(item)}
                model={item}
                setSelectedModelId={setSelectedModelId}
              />
            )
          })
        }

        <button aria-label="next" onClick={e => {
          setModelStartId(modelStartId + defaultNum > modelList.length - 1 ? 0 : modelStartId + defaultNum)
          handleGtag(e, "model", "model proceed")
        }}>
          <IoIosArrowForward />
        </button>
      </div>

      <div className={selectedModelId === null ? "modal" : "modal is-active"}>
        <div className="modal-background" onClick={() => setSelectedModelId(null)}></div>
        <div className={darkMode ? "modal-content has-background-dark" : "modal-content has-background-white-bis"} style={{ height: "80vh", width: mobileMode ? "100%" : tabletMode ? "420px" : "640px", padding: "10px", borderRadius: "10px" }}>
          <AgentSetupWindow selectedModelId={selectedModelId} isSmallScreen={isSmallScreen} darkMode={darkMode} />
        </div>
      </div>
    </HelmetProvider >
  )
}
