import { useCallback, useEffect } from "react"
import { ReactFlow, useNodesState, useEdgesState, addEdge, Controls, Background } from '@xyflow/react'
import { demoCodeList } from "../constants/usecaseList"
import '@xyflow/react/dist/style.css'



export const WorkflowWindow = ({ selectedId, visual }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState()
  const [edges, setEdges, onEdgesChange] = useEdgesState()

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  )

  useEffect(() => {
    if (selectedId !== null) {
      setNodes(demoCodeList[selectedId]["initNodes"])
      setEdges(demoCodeList[selectedId]["initEdges"])
    }
  }, [setEdges, setNodes, selectedId])

  useEffect(() => {
    if ((visual !== undefined && visual !== null) && Object.keys(visual)) {
      setNodes(visual["nodes"])
      setEdges(visual["edges"])
    }
  }, [visual, setEdges, setNodes])


  return (
    <div className="has-background-white-ter" style={{ width: "100%", height: '64vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
      >
        <Controls />
        {/* <MiniMap /> */}
        <Background variant="dots" gap={12} size={1} />
      </ReactFlow>
    </div>
  )
}