import { useState, Fragment } from "react"
import { CiCircleInfo } from "react-icons/ci"


export const SupportInfo = ({ i, exp, styleProps }) => {
  const [expId, setExpId] = useState(null)

  return (
    <Fragment>
      &nbsp;
      <CiCircleInfo onMouseEnter={() => setExpId(i)} onMouseLeave={() => setExpId(null)} />

      {i === expId &&
        <div className="has-background-white" style={{ padding: "5px", borderRadius: "5px", width: "200px", height: "auto", position: "absolute", zIndex: 5, ...styleProps }}>
          <p style={{ fontSize: "12px", fontStyle: "italic" }}>{exp}</p>
        </div>
      }
    </Fragment>

  )
}
