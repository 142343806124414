import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Header } from "./components/Header"
import { Footer } from "./components/Footer"
import { Home } from "./components/Home"
import { CodeDemoWindow } from "./components/code/CodeDemoWindow"
import { CodeResultWindow } from "./components/code_results/CodeResultWindow"
import { DestinationAuthSuccess } from "./components/deploy/_OAuth2"
import { Deploy } from "./components/deploy/Deploy"
import { Message } from "./components/_utils/Message"
import { MaintenanceMode } from "./components/MaintenanceMode"


export const App = ({ mobileMode, tabletMode, darkMode }) => {
  const maintenanceMode = Boolean(process.env.REACT_APP_MAINTENANCE_MODE === "true" && process.env.REACT_APP_DEV_MODE !== "dev")
  const dt = process.env.REACT_APP_DT

  return (
    <BrowserRouter>
      <Header maintenanceMode={maintenanceMode} mobileMode={mobileMode} tabletMode={tabletMode} darkMode={darkMode} />
      <Routes>
        <Route path="*" element={<Message message="Page not found" />} />
        <Route
          exact path="/"
          element={
            maintenanceMode
              ? <MaintenanceMode dt={dt} />
              : <Home mobileMode={mobileMode} tabletMode={tabletMode} darkMode={darkMode} />
          }
        />
        <Route
          exact path="/playground"
          element={
            maintenanceMode
              ? <MaintenanceMode dt={dt} />
              : <CodeDemoWindow mobileMode={mobileMode} tabletMode={tabletMode} darkMode={darkMode} />
          }
        />
        <Route
          exact path="/result"
          element={
            maintenanceMode
              ? <MaintenanceMode dt={dt} />
              : <CodeResultWindow mobileMode={mobileMode} tabletMode={tabletMode} darkMode={darkMode} />
          }
        />
        <Route path=":serviceName?/success*" element={<DestinationAuthSuccess />} />
        <Route path="/deploy" element={<Deploy />} />
      </Routes>
      <Footer mobileMode={mobileMode} tabletMode={tabletMode} />
    </BrowserRouter >
  )
}
