import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { HelmetProvider, Helmet } from "react-helmet-async"
import { useKeyPress } from "./hooks/useKeyPress"
import { WorkflowWindow } from "./WorkflowWindow"
import { CodeEditorWindow } from "./CodeEditorWindow"
import { OutputDetails } from "./OutputWindow"
import { CodeSideMenu } from "./CodeSideMenu"
import { LogStream } from "./LogStream"
import { demoCodeList } from "../constants/usecaseList"
import { apiRouteUrl } from "../../lib/.api"
import { handleGtag } from "../_utils/gtag"
import { StickyMesssageBar } from "../_utils/Elements"

// const socket = new WebSocket("ws://localhost:5002")

// socket.addEventListener("open", (event) => {
//   socket.send("Hello Server!")
// })

// socket.addEventListener("message", (event) => {
//   console.log("Message from server ", event.data)
// })


export const CodeDemoWindow = ({ tabletMode, mobileMode, darkMode, filteredId = null }) => {
  const { hash } = useLocation()

  const [selectedId, setSelectedId] = useState(0)
  const [code, setCode] = useState(demoCodeList[0]["codeString"])
  const [inputSec, setInputSec] = useState(null)
  const [visual, setVisual] = useState(null)

  const [customInput, setCustomInput] = useState(null)

  const [start, setStart] = useState(false)
  const [processing, setProcessing] = useState(null)
  const [statusId, setStatusId] = useState(0)

  const [outputList, setOutputList] = useState(null)
  const [tokens, setTokens] = useState(null)
  const [latency, setLatency] = useState(null) // ms

  const enterPress = useKeyPress("Enter")
  const ctrlPress = useKeyPress("Control")

  const smallScreen = tabletMode || mobileMode
  const [isOpen, setIsOpen] = useState(false)

  const handleCustomInput = (key, e) => {
    const input = { ...customInput, [key]: e.target.value }
    setCustomInput(input)

    let baseCode = demoCodeList[selectedId]["codeString"]
    let keys = Object.keys(input)

    if (keys.length > 0) {
      let newCode = baseCode

      for (let i = 0; i < keys.length; i++) {
        const target = `{${keys[i]}}`
        const value = input[keys[i]]
        var count = newCode.split(target).length

        if (count === 1) {
          newCode = newCode.replace(target, value)
          setCode(newCode)
        }
        else {
          for (let j = 0; j < count; j++) {
            newCode = newCode.replace(target, value)
          }
          setCode(newCode)
        }
      }
    }
  }

  useEffect(() => {
    const el = hash !== undefined || hash !== "" ? Number(hash.replace("#", "")) : null
    if (el !== null && demoCodeList.length > el) {
      setSelectedId(el)
    }
  }, [hash])

  useEffect(() => {
    if (filteredId !== null) setSelectedId(filteredId)
    setStart(false);
    setCode(demoCodeList[selectedId]["codeString"])
    setInputSec(demoCodeList[selectedId]["vars"])
    setOutputList(null); setTokens(null); setLatency(null); setStatusId(0); setCustomInput(null)
  }, [selectedId, filteredId])

  useEffect(() => {
    const handleCompile = async () => {
      setProcessing(true)
      setStatusId(1);
      const api = demoCodeList[selectedId]["api"]

      setOutputList(null);

      await fetch(`${apiRouteUrl}/code/${api}`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Origin": window.location.origin,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ source_code: code, customInput })
      })
        .then(res => {
          if (res.status !== 200 || res.data === null) { setProcessing(false); setStatusId(4) }
          else return Promise.resolve(res.clone().json())
        })
        .then(res => {
          setOutputList(res["output_list"])
          setTokens(res["tokens"])
          setLatency(res["latency"])
          setProcessing(false)
          setStatusId(2)
          if (res["visual"] !== undefined) setVisual(res["visual"])
        })
        .catch(err => {
          let status = err.response && err.response.status ? err.response.status : null
          if (status === 429) setStatusId(4)
          else setStatusId(6)
          setProcessing(false)
        })

      setProcessing(false)
    }
    if (start === true && statusId === 0) handleCompile()
  }, [ctrlPress, enterPress, code, selectedId, start, customInput, statusId])

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <title>Playground | Experiment agentic frameworks for task automation</title>
        <link rel="canonical" href="https://versi0n.io/playground" />
        <meta name="description" content="Coding playground for agentic orchestration frameworks" />
      </Helmet>

      <div className="CodeDemo content" style={{ display: "flex" }}>
        <h1 style={{ display: "none" }}>Playground</h1>

        {filteredId === null &&
          <CodeSideMenu mobileMode={mobileMode} tabletMode={tabletMode} setCode={setCode} setSelectedId={setSelectedId} selectedId={selectedId} />
        }

        {statusId === 2 &&
          <StickyMesssageBar>
            <p className="help">Success</p>
          </StickyMesssageBar>
        }

        {statusId === 4 &&
          <StickyMesssageBar color="is-danger is-light">
            <p className="help">Time Limit Exceeded</p>
          </StickyMesssageBar>
        }
        {statusId === 6 &&
          <StickyMesssageBar color="is-danger is-light">
            <p className="help">Runtime Error (SIGSEGV)</p>
          </StickyMesssageBar>
        }

        <div id={`#${selectedId}`} style={{ display: "flex", flexDirection: mobileMode || tabletMode ? "column" : undefined, width: mobileMode ? "100%" : undefined }}>
          {smallScreen
            ?
            <div style={{ margin: "10px" }}>
              <div onClick={() => setIsOpen(!isOpen)}><p className="help"> System Diagram</p></div>
              {isOpen &&
                <div>
                  <WorkflowWindow selectedId={selectedId} visual={visual} />
                  <ul>
                    {demoCodeList[selectedId]["features"] !== undefined && demoCodeList[selectedId]["features"]
                      .map((item, i) => <li key={i} style={{ fontSize: "12px" }}>{item}</li>)
                    }
                  </ul>
                </div>
              }
            </div>
            :
            <div style={{ width: mobileMode ? "100%" : "420px", padding: "0 5px" }}>
              <h3 style={{ fontSize: "16px" }}>System</h3>
              <WorkflowWindow selectedId={selectedId} visual={visual} />
              <ul>
                {demoCodeList[selectedId]["features"] !== undefined && demoCodeList[selectedId]["features"]
                  .map((item, i) => <li key={i} style={{ fontSize: "12px" }}>{item}</li>)
                }
              </ul>
            </div>
          }

          <div style={{ width: mobileMode ? "100%" : "560px" }}>
            <CodeEditorWindow code={code} setCode={setCode} mobileMode={mobileMode} darkMode={darkMode} />

            {inputSec === undefined &&
              <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0" }}>
                <button
                  className="button is-small is-dark"
                  style={{ position: "relative", right: 0 }}
                  onClick={e => { handleGtag(e, "demo"); setStart(true); setStatusId(0) }}
                  disabled={!code}
                >
                  {processing ? "Processing..." : "Compile and Execute"}
                </button>
              </div>
            }
          </div>

          {inputSec !== undefined &&
            <div style={{ width: mobileMode ? "100%" : "240px", padding: "0 5px" }}>
              <h3 style={{ fontSize: "16px" }}>Custom Input</h3>
              {inputSec !== null && inputSec !== undefined && inputSec.length > 0 && inputSec.map((item, i) => {
                const placeholder = item["placeholder"] !== undefined ? item["placeholder"] : ""
                const { title, key, type } = item
                return (
                  <label key={i} style={{ fontSize: "14px", display: "inline-block", width: "100%", margin: "6px 0" }}>
                    {title !== undefined ? title : key}
                    {type === "textarea"
                      ?
                      <textarea
                        className="textarea is-small" placeholder={placeholder} rows={3}
                        onChange={e => handleCustomInput(key, e)}
                      />
                      :
                      <input
                        className="input is-small" type="text" placeholder={placeholder}
                        onChange={e => handleCustomInput(key, e)}
                      />
                    }
                  </label>
                )
              })
              }
              <button
                className="button is-small is-dark"
                style={{ position: "relative", right: 0, margin: "10px 0" }}
                onClick={e => { handleGtag(e, "demo"); setStart(true); setStatusId(0) }}
                disabled={!code}
              >
                {processing ? "Processing..." : "Compile and Execute"}
              </button>
            </div>
          }

          <div style={{ width: mobileMode ? "100%" : "240px", padding: "0 5px" }}>
            <h3 style={{ fontSize: "16px" }}>Preview</h3>

            {outputList === null &&
              <pre style={{ fontSize: "10px", minHeight: "100px", display: "inline-block", width: "100%" }}>
                <LogStream start={start} selectedId={selectedId} outputList={outputList} processing={processing} />
              </pre>

            }

            {outputList && outputList.length > 0 && outputList.map((item, i) => {
              const key = Object.keys(item) ? Object.keys(item)[0] : null
              const val = key ? item[key] : null

              return (
                <div key={i} style={{ marginTop: "10px" }}>
                  {key !== null && val !== null ?
                    <label style={{ fontSize: "14px" }}>{key.charAt(0).toUpperCase() + key.slice(1)}
                      {typeof (val) === "object"
                        ? val.map((v, id) => <pre key={id}>{v}</pre>)
                        : <textarea className="textarea is-small" defaultValue={String(val)} rows={val ? Math.min(Math.ceil(String(val).length / 30), 8) : 2} />
                      }
                    </label>
                    :
                    <></>
                  }
                </div>
              )
            })}

            {outputList && outputList.length > 0 && < OutputDetails statusId={statusId} tokens={tokens} latency={latency} />}
          </div>

        </div>

      </div>
    </HelmetProvider >
  )
}

