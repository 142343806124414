import { useState, useEffect } from "react"
import Editor from "@monaco-editor/react"

export const CodeEditorWindow = ({ code, setCode, mobileMode, darkMode }) => {
  const [value, setValue] = useState(code ? code : "// some comment")

  useEffect(() => {
    setValue(code);
  }, [setValue, code, setCode])


  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        if (setCode) setCode(data)
        break
      }
      default: {
        console.warn("case not handled!", action, data)
      }
    }
  }

  const handleEditorChange = (value) => {
    setValue(value)
    onChange("code", value)
  }

  return (
    <Editor
      height={mobileMode ? "500px" : "650px"}
      width="100%"
      language="python"
      value={value}
      theme={darkMode ? "vs-dark" : "brilliance-dull"}
      defaultValue={code}
      onChange={handleEditorChange}
    />
  )
}
