import { useState } from "react"
import { useLocation } from "react-router-dom"
import { HelmetProvider, Helmet } from "react-helmet-async"
import { WorkflowWindow } from "../code/WorkflowWindow"
import { CodeEditorWindow } from "../code/CodeEditorWindow"
import { OutputDetails } from "../code/OutputWindow"
import { ModelButton } from "../agent/ModelButton"


export const CodeResultWindow = ({ tabletMode, mobileMode, darkMode }) => {
  const { state } = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const smallScreen = tabletMode || mobileMode

  const res = state && state["res"] !== undefined ? state["res"] : null
  const model = state && state["model"] !== undefined ? state["model"] : null

  if (!res) return (
    <div className="container content" style={{ minHeight: "700px", padding: "120px 80px", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <p>Something went wrong. Select a model from <a href="/" style={{ textDecoration: "underline" }}>here</a> and retry.</p>
    </div>
  )

  const { code, visual, outputList, tokens, latency } = res
  return (
    <HelmetProvider>
      <Helmet>
        <title>Result</title>
        <link rel="canonical" href="https://versi0n.io/result" />
        <meta name="description" content="Coding playground for agentic orchestration frameworks" />
      </Helmet>

      <div className="CodeDemo content" style={{ display: "flex" }}>
        <h1 style={{ display: "none" }}>Result</h1>

        <div style={{ width: mobileMode ? "100%" : "560px" }}>
          <CodeEditorWindow code={code} mobileMode={mobileMode} darkMode={darkMode} />
        </div>

        <div style={{ width: mobileMode ? "100%" : "420px", padding: "0 5px" }}>
          <h3 style={{ fontSize: "16px" }}>Output</h3>
          <ModelButton model={model} disabled={true} />
          {outputList && outputList.length > 0 && outputList.map((item, i) => {
            const key = Object.keys(item) ? Object.keys(item)[0] : null
            const val = key ? item[key] : null
            return (
              key
                ?
                <div key={i} style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "14px" }}>{key.charAt(0).toUpperCase() + key.slice(1)}
                    {typeof (val) === "object"
                      ? val.map((v, id) => <pre key={id}>{v}</pre>)
                      : <textarea className="textarea is-small" defaultValue={val} rows={val ? Math.min(Math.ceil(val.length / 30), 8) : 2} />
                    }
                  </label>
                </div>
                :
                <div key={i}></div>
            )
          })}
          {outputList && outputList.length > 0 && < OutputDetails statusId={2} tokens={tokens} latency={latency} />}
        </div>

        <div style={{ display: "flex", flexDirection: mobileMode || tabletMode ? "column" : undefined, width: mobileMode ? "100%" : undefined }}>
          {smallScreen
            ?
            <div style={{ margin: "10px" }}>
              <div onClick={() => setIsOpen(!isOpen)}><p className="help"> Diagram</p></div>
              {isOpen && <WorkflowWindow selectedId={null} visual={visual} />}
            </div>
            :
            <div style={{ width: mobileMode ? "100%" : "420px", padding: "0 5px" }}>
              <h3 style={{ fontSize: "16px" }}>Task Graph</h3>
              <WorkflowWindow selectedId={null} visual={visual} />
            </div>
          }
        </div>
      </div>
    </HelmetProvider >
  )
}

