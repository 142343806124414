import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./App"
import "./main.scss"


var mobileMode = Boolean(window.innerWidth <= 430)
var tabletMode = Boolean(window.innerWidth > 430 && window.innerWidth < 850)
var darkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? true : false


const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<App tabletMode={tabletMode} mobileMode={mobileMode} darkMode={darkMode} />)
