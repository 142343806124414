export const modelList = [
  {

    name: "Gemini 2.5 Pro",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115167/pj_m_model_logos/gemini_hz1evn.png",
    exp: "Google's most advanced model",
    isNew: true,
    isHighlight: true,
    credential: true,
    providers: {
      "Google": {
        key: "gemini/gemini-2.5-pro-exp-03-25",
        credentials: ["GEMINI_API_KEY",],
        providerUrl: "https://ai.google.dev/",
      },
    },
  },
  {
    key: "gemini/gemini-2.0-flash",
    name: "Gemini 2.0 Flash",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115167/pj_m_model_logos/gemini_hz1evn.png",
    exp: "Google's model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "gemini/gemini-2.0-flash-lite-preview-02-05",
    name: "Gemini 2.0 Flash Lite",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115167/pj_m_model_logos/gemini_hz1evn.png",
    exp: "Google's fastest model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "gemini/gemini-2.0-flash-thinking-exp",
    name: "Gemini 2.0 Thinking",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115167/pj_m_model_logos/gemini_hz1evn.png",
    exp: "Google's latest reasoning model",
    isNew: false,
    isHighlight: false,
  },
  {
    name: "Phi 4",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1742640444/pj_m_model_logos/ko9aak59wpjtmt4kjwis.png",
    exp: "Microsoft's most efficient small model",
    isNew: false,
    isHighlight: false,
    credential: true,
    providers: {
      "Azure AI": {
        key: "azure_ai/Phi-4-mini-instruct",
        credentials: ["AZURE_AI_API_KEY", "AZURE_AI_API_BASE",],
        providerUrl: "https://learn.microsoft.com/en-us/azure/ai-foundry/model-inference/concepts/endpoints?tabs=python#routing",
      },
      "Azure OpenAI": {
        key: "azure/Phi-4-mini-instruct",
        credentials: ["AZURE_OPENAI_API_KEY", "AZURE_OPENAI_ENDPOINT",],
        providerUrl: "https://learn.microsoft.com/en-us/azure/ai-foundry/model-inference/concepts/endpoints?tabs=python#routing",
      }
    },
  },
  {
    name: "Deepseek V3",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115313/pj_m_model_logos/deepseek_uogple.png",
    exp: "Deepseek's most latest model",
    isNew: false,
    isHighlight: true,
    credential: true,
    providers: {
      "Azure AI": {
        key: "azure_ai/DeepSeek-V3",
        credentials: ["AZURE_AI_API_KEY", "AZURE_AI_API_BASE",],
        providerUrl: "https://learn.microsoft.com/en-us/azure/ai-foundry/model-inference/concepts/endpoints?tabs=python#routing",
      },
      "Azure OpenAI": {
        key: "azure/DeepSeek-V3",
        credentials: ["AZURE_OPENAI_API_KEY", "AZURE_OPENAI_ENDPOINT",],
        providerUrl: "https://learn.microsoft.com/en-us/azure/ai-foundry/model-inference/concepts/endpoints?tabs=python#routing",
      },
      "OpenRouter": {
        key: "openrounter/deepseek/deepseek-chat",
        credentials: ["OPENROUTER_API_KEY",],
        providerUrl: "https://accounts.openrouter.ai/sign-in?redirect_url=https%3A%2F%2Fopenrouter.ai%2Fsettings%2Fkeys",
      }
    },
  },
  {
    name: "Deepseek R1",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115313/pj_m_model_logos/deepseek_uogple.png",
    exp: "Deepseek's most advanced model",
    isNew: false,
    isHighlight: false,
    credential: true,
    providers: {
      "Azure AI": {
        key: "azure_ai/DeepSeek-R1",
        credentials: ["AZURE_AI_API_KEY", "AZURE_AI_API_BASE",],
        providerUrl: "https://learn.microsoft.com/en-us/azure/ai-foundry/model-inference/concepts/endpoints?tabs=python#routing",
      },
      "Azure OpenAI": {
        key: "azure/DeepSeek-R1",
        credentials: ["AZURE_OPENAI_API_KEY", "AZURE_OPENAI_ENDPOINT",],
        providerUrl: "https://learn.microsoft.com/en-us/azure/ai-foundry/model-inference/concepts/endpoints?tabs=python#routing",
      },
      "OpenRouter": {
        key: "openrouter/deepseek/deepseek-r1",
        credentials: ["OPENROUTER_API_KEY",],
        providerUrl: "https://accounts.openrouter.ai/sign-in?redirect_url=https%3A%2F%2Fopenrouter.ai%2Fsettings%2Fkeys",
      }
    },
  },
  {
    name: "Qwen 2.5",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115833/pj_m_model_logos/qwen_zah09d.png",
    exp: "Alibaba's most advanced model",
    isNew: false,
    isHighlight: true,
    credential: true,
    providers: {
      "OpenRouter": {
        key: "openrouter/qwen/qwen-2.5-72b-instruct",
        credentials: ["OPENROUTER_API_KEY",],
        providerUrl: "https://accounts.openrouter.ai/sign-in?redirect_url=https%3A%2F%2Fopenrouter.ai%2Fsettings%2Fkeys",
      }
    },
  },
  {
    key: "gpt-4.5-preview-2025-02-27",
    name: "GPT-4.5",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/gpt_new_ttekyf.png",
    exp: "OpenAI's latest language model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "gpt-4o",
    name: "GPT-4o",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/gpt_new_ttekyf.png",
    exp: "OpenAI's advanced language model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "gpt-4o-mini",
    name: "GPT-4o mini",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/gpt_new_ttekyf.png",
    exp: "OpenAI's most efficient language model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "o3-mini",
    name: "o3 Mini",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1742711048/pj_m_model_logos/zzgi8mjrpuwj3j4moetu.png",
    exp: "OpenAI's latest reasoning model",
    isNew: false,
    isHighlight: true,
  },
  {
    key: "o1-mini",
    name: "o1 Mini",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1742711048/pj_m_model_logos/zzgi8mjrpuwj3j4moetu.png",
    exp: "OpenAI's efficient reasoning model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "o1-preview",
    name: "o1 Preview",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1742711048/pj_m_model_logos/zzgi8mjrpuwj3j4moetu.png",
    exp: "OpenAI's advanced reasoning model",
    isNew: false,
    isHighlight: false,
  },
  {
    name: "Claude 3.7 Sonnet",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/anthropic_nhzori.png",
    exp: "Anthropic's latest model",
    isNew: false,
    isHighlight: true,
    credential: true,
    providers: {
      "Anthropic": {
        key: "claude-3-7-sonnet-latest",
        credentials: ["ANTHROPIC_API_KEY",],
        providerUrl: "https://console.anthropic.com/settings/keys",
      }
    },
  },
  {
    name: "Claude 3.5 Sonnet",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/anthropic_nhzori.png",
    exp: "Anthropic's most advanced model",
    isNew: false,
    isHighlight: false,
    credential: true,
    providers: {
      "Anthropic": {
        key: "claude-3-5-sonnet-latest",
        credentials: ["ANTHROPIC_API_KEY",],
        providerUrl: "https://console.anthropic.com/settings/keys",
      }
    },
  },
  {
    name: "Claude 3.5 Haiku",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/anthropic_nhzori.png",
    exp: "Anthropic's fastest model",
    isNew: false,
    isHighlight: false,
    credential: true,
    providers: {
      "Anthropic": {
        key: "claude-3-5-haiku-latest",
        credentials: ["ANTHROPIC_API_KEY",],
        providerUrl: "https://console.anthropic.com/settings/keys",
      }
    },
  },
  {
    name: "Claude 3 Opus",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/anthropic_nhzori.png",
    exp: "Anthropic's legacy advanced model",
    isNew: false,
    isHighlight: false,
    credential: true,
    providers: {
      "Anthropic": {
        key: "claude-3-opus-latest",
        credentials: ["ANTHROPIC_API_KEY",],
        providerUrl: "https://console.anthropic.com/settings/keys",
      }
    },
  },
  {
    key: "bedrock/converse/us.meta.llama3-3-70b-instruct-v1:0",
    name: "Llama 3.3",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115314/pj_m_model_logos/llama_pzv7kp.png",
    exp: "Meta's most advanced model",
    isNew: false,
    isHighlight: true,
  },
  {
    key: "bedrock/us.meta.llama3-2-90b-instruct-v1:0",
    name: "Llama 3.2 90B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115314/pj_m_model_logos/llama_pzv7kp.png",
    exp: "Meta's legacy advanced model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "bedrock/us.meta.llama3-2-11b-instruct-v1:0",
    name: "Llama 3.2 11B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115314/pj_m_model_logos/llama_pzv7kp.png",
    exp: "Meta's most efficient model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "bedrock/mistral.mistral-large-2407-v1:0",
    name: "Mistral Large 2",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115314/pj_m_model_logos/mistral_utpmjs.png",
    exp: "Mistral AI's most advanced model",
    isNew: false,
    isHighlight: true,
  },
  {
    name: "DBRX - Instruct",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115669/pj_m_model_logos/databricks_h1cth8.png",
    exp: "Databricks' latest model",
    isNew: false,
    isHighlight: false,
    credential: true,
    providers: {
      "OpenRouter": {
        key: "openrouter/databricks/dbrx-instruct",
        credentials: ["OPENROUTER_API_KEY",],
        providerUrl: "https://accounts.openrouter.ai/sign-in?redirect_url=https%3A%2F%2Fopenrouter.ai%2Fsettings%2Fkeys",
      }
    },
  },
  {
    name: "Command R+",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115668/pj_m_model_logos/cohere_jmaw0l.png",
    exp: "Cohere's advanced model",
    isNew: false,
    isHighlight: false,
    credential: true,
    providers: {
      "Amazon Bedrock": {
        key: "bedrock/cohere.command-r-plus-v1:0",
        credentials: ["AWS_ACCESS_KEY_ID", "AWS_SECRET_ACCESS_KEY"],
        providerUrl: "https://dashboard.cohere.com/welcome/login?redirect_uri=%2Fapi-keys%3F_gl%3D1*14v2pj5*_gcl_au*NTczMTgyMTIzLjE3MzQ1NTY2OTA.*_ga*MTAxNTg1NTM1MS4xNjk1MjMwODQw*_ga_CRGS116RZS*MTczNjI3NzU2NS4xOS4xLjE3MzYyODExMTkuNDkuMC4w",
      },
      "Azure AI": {
        key: "azure/command-r-plus",
        credentials: ["AZURE_AI_API_KEY", "AZURE_AI_API_BASE",],
        providerUrl: "https://learn.microsoft.com/en-us/azure/ai-foundry/model-inference/concepts/endpoints?tabs=python#routing",
      },
      "Azure OpenAI": {
        key: "azure_ai/command-r-plus",
        credentials: ["AZURE_OPENAI_API_KEY", "AZURE_OPENAI_ENDPOINT",],
        providerUrl: "https://learn.microsoft.com/en-us/azure/ai-foundry/model-inference/concepts/endpoints?tabs=python#routing",
      },
    },
  },
  {
    name: "Command R",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115668/pj_m_model_logos/cohere_jmaw0l.png",
    exp: "Cohere's fastest model",
    isNew: false,
    isHighlight: false,
    credential: true,
    providers: {
      "Amazon Bedrock": {
        key: "bedrock/cohere.command-r-v1:0",
        credentials: ["AWS_ACCESS_KEY_ID", "AWS_SECRET_ACCESS_KEY"],
        providerUrl: "https://dashboard.cohere.com/welcome/login?redirect_uri=%2Fapi-keys%3F_gl%3D1*14v2pj5*_gcl_au*NTczMTgyMTIzLjE3MzQ1NTY2OTA.*_ga*MTAxNTg1NTM1MS4xNjk1MjMwODQw*_ga_CRGS116RZS*MTczNjI3NzU2NS4xOS4xLjE3MzYyODExMTkuNDkuMC4w",
      },
    }
  },
  {
    key: "bedrock/amazon.titan-text-lite-v1",
    name: "Titan",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1740641515/pj_m_model_logos/h5sbblwe5tbhhjwozfjm.png",
    exp: "Amazon's latest model",
    isNew: false,
    isHighlight: false,
    credential: false,
  },
]
