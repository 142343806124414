export const statusList = [
  {
    id: 0,
    description: "In Queue",
  },
  {
    id: 1,
    description: "Processing",
  },
  {
    id: 2,
    description: "Accepted",
  },
  {
    id: 3,
    description: "Wrong Answer",
  },
  {
    id: 4,
    description: "Time Limit Exceeded",
  },
  {
    id: 5,
    description: "Compilation Error",
  },
  {
    id: 6,
    description: "Runtime Error (SIGSEGV)",
  },
  {
    id: 7,
    description: "Runtime Error (SIGXFSZ)",
  },
  {
    id: 8,
    description: "Runtime Error (SIGFPE)",
  },
  {
    id: 9,
    description: "Runtime Error (SIGABRT)",
  },
  {
    id: 10,
    description: "Runtime Error (NZEC)",
  },
  {
    id: 11,
    description: "Runtime Error (Other)",
  },
  {
    id: 12,
    description: "Internal Error",
  },
  {
    id: 13,
    description: "Exec Format Error",
  },
];
